console.log('Ready javascript');
// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);

$('.gallery-item a').attr('data-fancybox', 'gallery');

$('.gallery-item a').addClass('dim');
$('.c-page a img').addClass('dim');

$('.fancybox').attr('data-fancybox', 'gallery');

const navLinks = document.querySelectorAll(".c-menu nav a");
const navTextCopy = document.getElementById("nav_big_text");

if (navTextCopy) { 
for (var i=0; i<navLinks.length; i++){
  navLinks[i].addEventListener("mouseover",function(){
    let navLinkText = this.textContent;
    navTextCopy.textContent = navLinkText;
    navTextCopy.classList.add("big_text_active");
  });
  
  navLinks[i].addEventListener("mouseout",function(){
    let navLinkText = this.textContent;
    navTextCopy.textContent = navLinkText; 
    navTextCopy.classList.remove("big_text_active");
  });
}


// navTextCopy.classList.add("big_text_active");
// navTextCopy.textContent = navLinks[0].textContent;
}
// $(".js-c-social-post__button").click(function(){ 
//   $(".c-social-post").toggleClass("aos-init aos-animate");
// });



























