// Vendor  node_modules jQuery
// import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// Vendor node_modules 
require('@fancyapps/fancybox');
// require('flickity');

// require('material-scrolltop');

// Vendor node_modules 
// var $ = require('jquery');
// var jQueryBridget = require('jquery-bridget');


// Scripts
require('./modules/scripts');
// require('./modules/sentence');



	









